import { QuerySubState } from '@reduxjs/toolkit/dist/query/core/apiState'
import { API } from 'project/api'

import {
  FetchArgs,
  createApi,
  BaseQueryFn,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { AnyAction } from '@reduxjs/toolkit'
import { HYDRATE } from 'next-redux-wrapper'
import { CreateApiOptions } from '@reduxjs/toolkit/dist/query/createApi'
import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { Project } from './project'
import { REHYDRATE } from 'redux-persist'
import { Constants } from './constants'

export function extractRehydrationInfo(
  action: AnyAction,
  {
    reducerPath,
  }: {
    reducerPath: string
  },
) {
  if (action.type === HYDRATE) {
    return action.payload[reducerPath]
  }
}

const webApi = API.api || Project.api

let csrfResponse: Promise<Headers> | null = null
export const getXsrf = (forceFetch?: boolean) => {
  if (!csrfResponse || forceFetch) {
    csrfResponse = fetch(`${webApi}sanctum/csrf-cookie`, {
      headers: {
        'X-Cookie-Domain': API.api ? document.location.hostname : 'justfix.app',
      },
    }).then((res) => res.headers)
  }
  return csrfResponse
}

const isMobile = API.isMobile()

export const baseApiOptions = (queryArgs?: Partial<FetchBaseQueryArgs>) => {
  const bq = fetchBaseQuery({
    baseUrl: webApi,
    prepareHeaders: async (headers, { endpoint, getState }) => {
      if (isMobile) {
        const token = await API.storage?.getItem('userToken')
        if (token) {
          headers.set('Authorization', `Bearer ${token}`)
        }
      } else {
        await getXsrf()
        const cookie = await API.cookies.getItem('XSRF-TOKEN')

        if (cookie) {
          headers.set('X-XSRF-TOKEN', decodeURIComponent(cookie))
        }
        headers.set('referer', Project.referrer)
        headers.set('origin', Project.referrer)
      }

      if (API.api) {
        headers.set('X-Cookie-Domain', document.location.hostname)
      }
      headers.set('Accept', 'application/json')
      headers.set('Accept-Language', 'en-GB,en;q=0.9')
      if (Constants.E2E) {
        headers.set('E2E-Test', '1')
      }
      return headers
    },
    ...queryArgs,
  })

  const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    const result = await bq(args, api, extraOptions)
    const userData = (api.getState() as any)?.service?.queries?.['getUser({})']
      ?.data
    if (result.error && result.error.status === 401) {
      if (isMobile) {
        await API.storage?.removeItem('userToken')
      }
      if (userData) {
        api.dispatch(service.util.resetApiState())
        API.resetUserMixpanel()
      }
    } else if (result.error?.status === 419 && api.endpoint === 'login') {
      await getXsrf(true)
      throw new Error('Something went wrong. Please try again.')
    }
    return result
  }

  const res: Pick<
    CreateApiOptions<any, any, any, any>,
    | 'baseQuery'
    | 'keepUnusedDataFor'
    | 'refetchOnReconnect'
    | 'refetchOnFocus'
    | 'extractRehydrationInfo'
  > = {
    baseQuery: baseQueryWithReauth,
    extractRehydrationInfo: (action, { reducerPath }) => {
      if (action.type === HYDRATE) {
        return action.payload[reducerPath]
      }
    },
    refetchOnFocus: true,
    refetchOnReconnect: true,
  }
  return res
}

const transformQuery = (query: QuerySubState<any> | undefined | null) => {
  if (query?.status !== 'fulfilled') {
    return null
  }
  return {
    ...query,
    fulfilledTimeStamp: null,
  }
}

export const service = createApi({
  ...baseApiOptions(),
  endpoints: () => ({}),
  // @ts-ignore
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE && action.payload?.service) {
      return {
        config: {},
        mutations: {},
        provided: {},
        queries: {
          'getPropertyAddress({})': transformQuery(
            action.payload?.service?.queries['getPropertyAddress({})'],
          ),
          'getServices({})': transformQuery(
            action.payload?.service?.queries['getServices({})'],
          ),
          'getUser({})': transformQuery(
            action.payload?.service?.queries['getUser({})'],
          ),
        },
        subscriptions: {},
      }
    }
  },
  keepUnusedDataFor: 10,
  reducerPath: 'service',
})
