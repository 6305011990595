import dayjs from 'dayjs'

// @ts-ignore
export const DEV = global.__DEV__
  ? // @ts-ignore
    global.__DEV__
  : typeof __DEV__ === 'undefined'
    ? false
    : __DEV__

export const Constants = {
  E2E: DEV && false,
  E2E_NAMESPACE: null,
  REQUEST_TIMEOUT: 10000,
  defaultLocale: 'en',
  enableSentry: !DEV,
  simulate: !DEV
    ? {}
    : {
        CONFIRM_EMAIL: false,
        DRAFT_BOOKING: {
          'address_id': undefined,
          'address_line_1': 'Essex Showground',
          'address_line_2': 'Great Leighs',
          'asap': 0,
          'county': 'Essex',
          'details': `These are some details, aren't they great.`,
          'email': `test+${Date.now()}@justfix.com`,
          'first_name': 'John',
          'last_name': 'Doe',
          'lat': process.env.CUSTOMER_LATITUDE,
          'lon': process.env.CUSTOMER_LONGITUDE,
          'phone': '07771212123',
          'postcode': 'CM3 1RD',
          'preferred_date': dayjs().format('YYYY-MM-DD'),
          'preferred_time': `${dayjs().format('dddd D MMMM YYYY')} - AM`,
          'required_time': 'AM',
          'town_or_city': 'Chelmsford',
        },
        DUMMY_LOGIN: {
          email: 'rashila@solidstategroup.com',
          password: 'Password@123',
        },
        DUMMY_OTP_LOGIN: {
          phone: '07123456789',
        },
        DUMMY_REGISTER: {
          email: `rashila.pandey+${Date.now()}@solidstategroup.com`,
          first_name: 'Rashila',
          last_name: 'Pandey',
          password: 'Password@123',
          password_confirmation: 'Password@123',
          phone: '07123456789',
          terms_and_conditions: true,
        },
        FORCE_LANGUAGE: '',
        FORCE_ONBOARDING: false,
        FORCE_PAGE: null,
        FORCE_SUB_PAGE: null,
        SKIP_PAYWALL: false,
        SKIP_WELCOME: false,
      },
}
