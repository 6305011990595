import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { getApi } from './api'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import { Persistor } from 'redux-persist/es/types'
import { service } from './service'
import { RedirectSlice } from './services/useRedirect'
import { codepushProgressSlice } from './services/mobile/useCodepushProgress'
import { draftBookingSlice } from './services/useDraftBooking'
// END OF IMPORTS
const createStore = () => {
  const storage =
    getApi().reduxStorage ||
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('redux-persist/lib/storage').default
  const reducer = combineReducers({
    [service.reducerPath]: service.reducer,
    codepushProgress: codepushProgressSlice.reducer,
    draftBooking: draftBookingSlice.reducer,
    redirect: RedirectSlice.reducer,
    // END OF REDUCERS
  })

  return configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(service.middleware)
        // END OF MIDDLEWARE
        .concat(getApi().middlewares || []),
    // @ts-ignore typescript is confused by the turnary
    reducer:
      typeof window !== 'undefined'
        ? persistReducer(
            {
              key: 'root',
              storage,
              version: 1,
              whitelist: ['service'],
            },
            reducer,
          )
        : reducer,
  })
}

type StoreType = ReturnType<typeof createStore>
let _store: StoreType
let _persistor: Persistor
export const getPersistor = () => _persistor
export const getStore = function (): StoreType {
  if (_store) return _store
  _store = createStore()
  _persistor = persistStore(_store)
  return _store
}

export type StoreStateType = ReturnType<StoreType['getState']>
