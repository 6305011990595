import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'

export const propertyAddressService = service
  .enhanceEndpoints({ addTagTypes: ['PropertyAddress'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createPropertyAddress: builder.mutation<
        Res['propertyAddress'],
        Req['createPropertyAddress']
      >({
        invalidatesTags: [{ id: 'LIST', type: 'PropertyAddress' }],
        query: (query: Req['createPropertyAddress']) => ({
          body: query,
          method: 'POST',
          url: `customer/customer-addresses`,
        }),
      }),
      getPropertyAddress: builder.query<
        Res['propertyAddress'],
        Req['getPropertyAddress']
      >({
        providesTags: (res) => [{ id: 'LIST', type: 'PropertyAddress' }],
        query: (query: Req['getPropertyAddress']) => ({
          url: `customer/customer-addresses`,
        }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function createPropertyAddress(
  store: any,
  data: Req['createPropertyAddress'],
  options?: Parameters<
    typeof propertyAddressService.endpoints.createPropertyAddress.initiate
  >[1],
) {
  store.dispatch(
    propertyAddressService.endpoints.createPropertyAddress.initiate(
      data,
      options,
    ),
  )
  return Promise.all(
    store.dispatch(propertyAddressService.util.getRunningQueriesThunk()),
  )
}

export async function getPropertyAddress(
  store: any,
  data: Req['getPropertyAddress'],
  options?: Parameters<
    typeof propertyAddressService.endpoints.getPropertyAddress.initiate
  >[1],
) {
  store.dispatch(
    propertyAddressService.endpoints.getPropertyAddress.initiate(data, options),
  )
  return Promise.all(
    store.dispatch(propertyAddressService.util.getRunningQueriesThunk()),
  )
}

// END OF FUNCTION_EXPORTS

export const {
  useCreatePropertyAddressMutation,
  useGetPropertyAddressQuery,

  // END OF EXPORTS
} = propertyAddressService

/* Usage examples:
const { data, isLoading } = useGetPropertyAddressQuery({ id: 2 }, {}) //get hook
const [createPropertyAddress, { isLoading, data, isSuccess }] = useCreatePropertyAddressMutation() //create hook
propertyAddressService.endpoints.getPropertyAddress.select({id: 2})(store.getState()) //access data from any function
*/
