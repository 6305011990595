import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Req } from 'common/types/requests'
import { Res } from 'common/types/responses'
import { StoreStateType } from 'common/store'

type InitialStateType = Res['draftBooking'] | null

const initialState = null as InitialStateType

export const draftBookingSlice = createSlice({
  initialState,
  name: 'draftBooking',
  reducers: {
    setDraftBooking(state, action: PayloadAction<Req['setDraftBooking']>) {
      state = action.payload
      return state
    },
  },
})

export const draftBookingActions = draftBookingSlice.actions
export const useDraftBookingActions = () => {
  const dispatch = useDispatch()
  const setDraftBooking = useCallback(
    (payload: Req['setDraftBooking']) => {
      return dispatch(draftBookingActions.setDraftBooking(payload))
    },
    [dispatch],
  )
  return { setDraftBooking }
}

const selectDraftBooking = (state: StoreStateType) => state.draftBooking

export const useDraftBooking = () => {
  const { setDraftBooking } = useDraftBookingActions()

  const draftBooking = useSelector(selectDraftBooking)
  return useMemo(
    () => ({ draftBooking, setDraftBooking }),
    [setDraftBooking, draftBooking],
  )
}
