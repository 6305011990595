import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'

export const serviceService = service
  .enhanceEndpoints({ addTagTypes: ['Service'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getServices: builder.query<Res['services'], Req['getServices']>({
        providesTags: [{ id: 'LIST', type: 'Service' }],
        query: () => ({
          url: `customer/services`,
        }),
        transformResponse: (res: Res['services']) => {
          const hasDisplayOrder = res.every(
            (item) => typeof item.display_order === 'number',
          )
          const services = hasDisplayOrder
            ? res.sort((a, b) => a.display_order - b.display_order)
            : res
          return services
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getServices(
  store: any,
  data: Req['getServices'],
  options?: Parameters<typeof serviceService.endpoints.getServices.initiate>[1],
) {
  return store.dispatch(
    serviceService.endpoints.getServices.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetServicesQuery,
  // END OF EXPORTS
} = serviceService

/* Usage examples:
const { data, isLoading } = useGetServicesQuery({ id: 2 }, {}) //get hook
const [createServices, { isLoading, data, isSuccess }] = useCreateServicesMutation() //create hook
serviceService.endpoints.getServices.select({id: 2})(store.getState()) //access data from any function
*/
