import Cookies from 'js-cookie'
import { redirect } from 'next/navigation'
import mixpanel from 'mixpanel-browser'
import { CookieAttributes } from 'node_modules/@types/js-cookie'

import { setApi } from 'common/api'
import Strings from './localisation'
import { Project } from 'common/project'
import storage from './async-storage-api'
import { Constants } from 'common/constants'
import { ApiTypes } from 'common/api/types/api-types'
interface WebAPI
  extends ApiTypes<
    any,
    {
      removeItem: (key: string, req?: any) => undefined | unknown
      getItemSync: (key: string, req?: any) => string
      setItemSync: (
        key: string,
        value: string,
        req?: any,
      ) => undefined | unknown
      removeItemSync: (key: string, req?: any) => undefined | unknown
    }
  > {
  getStoredLocale: (requestedLocale?: string) => string
  setStoredLocale: (requestedLocale: string) => void
  logoutRedirect: () => void
}

const API: WebAPI = {
  api: Project.proxy,
  confirmEmailRedirect(email) {
    redirect(`/auth/confirm-email?email=${email}`)
  },
  cookies: {
    getAllCookies: () => {
      return Cookies.get()
    },
    getItem: (name: string) => {
      return Cookies.get(name)
    },
    removeItem: (name: string) => {
      return Cookies.remove(name)
    },
    setCookie: (name: string, value: string, options?: CookieAttributes) => {
      return Cookies.set(name, value, options)
    },
  },
  getAPIBaseUrl: () => process.env.NEXT_PUBLIC_API_URL || '',
  getPlatform: () => 'web',
  getStoredLocale: (requestedLocale?: string) => {
    return (
      API.storage.getItemSync('NEXT_LOCALE') ||
      requestedLocale ||
      Constants.defaultLocale
    )
  },
  identifyUserMixpanel(user) {
    mixpanel.identify(`${user.id}`)
    mixpanel.people.set({
      '$email': user.email,
      '$name': `${user.first_name} ${user.last_name}`,
    })
  },

  isMobile: () => false,

  // @ts-ignore
  log(namespace: keyof (typeof Project)['logs'], ...args: any[]) {
    if (Project.logs[namespace]) {
      // eslint-disable-next-line no-console
      console.log.apply(this, [namespace, ...args])
    }
  },
  loginRedirect() {
    // const params = Router.query
    // // const profile:AppState['profile'] = _store().getState().profile;
    // params.redirect = params.redirect || '/account'
    // // @ts-ignore
    // Router.replace(params.redirect, params.as || params.redirect, {
    //   shallow: true,
    // })
  },

  logout: async () => {
    Cookies.remove('userToken')
  },

  logoutRedirect() {
    redirect('booking/select-service')
  },

  middlewares: [],

  resetUserMixpanel() {
    mixpanel.reset()
  },
  setStoredLocale: (locale: string) => {
    Strings.setLanguage(locale)
    API.storage.setItemSync('NEXT_LOCALE', locale)
    // document.location = document.location
    // @ts-ignore
    const { asPath, pathname, query } = Router.router?.state || {}
    // change just the locale and maintain all other route information including href's query
    // @ts-ignore
    Router.router?.push({ pathname, query }, asPath, { locale })
  },
  storage,
  trackEvent() {},
  trackEventMixpanel(eventName, properties) {
    mixpanel.track(`FE - ${eventName}`, properties)
  },
  trackPage() {},
}
setApi(API)
export { API }
